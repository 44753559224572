import React from 'react';

export default function Tabs({ activeTab, setActiveTab, tabs }) {
	return (
		<div className="border-b border-gray-200 px-6">
			<nav className="-mb-px flex space-x-8" aria-label="Tabs">
				{tabs.map((tab) => (
					<button
						key={tab.id}
						onClick={() => setActiveTab(tab.id)}
						className={`
              whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm
              ${
					activeTab === tab.id
						? 'border-pink-500 text-pink-600'
						: 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300'
				}
            `}
					>
						{tab.label}
					</button>
				))}
			</nav>
		</div>
	);
}
